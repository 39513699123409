// eslint-disable-next-line import/prefer-default-export
export const EVENTS = {
  updateList: 'update-stories-list',
  create: 'create-story',
  update: 'update-story',
};

// Statuses
export const PUBLISH_STATUS_PREPROCESSING = 'preprocessing';
export const PUBLISH_STATUS_PUBLISHING = 'publishing';
export const PUBLISH_STATUS_REPUBLISHING = 'republishing';
export const PUBLISH_STATUS_UNPUBLISHING = 'unpublishing';
export const PUBLISH_STATUS_COMPLETED = 'completed';

export const SEO_MIN_SCORE = {
  bad: 0 /* 0 - 24 */,
  ok: 25 /* 25 - 74 */,
  good: 75 /* 75 - 100 */,
};

export const TOPICS_SUGGESTION_STATUS = {
  loading: 'loading',
  loadingIntermediate: 'loading-intermediate',
  finished: 'finished',
  initial: 'initial',
  noKeyphrase: 'noKeyphrase',
  error: 'error',
};

export const STORY_WRITING_ANALYSIS_SIDEBAR_TAB_PARAM = 'tab';

export const STORY_WRITING_ANALYSIS_SIDEBAR_TAB = {
  topics: 'topics',
  seoBasics: 'seoBasics',
  readability: 'readability',
  wordCount: 'wordCount',
};
